<template>
  <div>
    <div
      v-if="liberaIconeChat"
      class="icone-chat"
      @click="alternarChat(chatAberto)"
      :class="{ digitando: carregandoResposta }"
    >
      <img
        :src="logoIAPadraoBranco"
        class="icone-robo"
        alt="icone assistente outbox"
        ref="iconeRobo"
      />
    </div>
    <transition name="chat" @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div v-if="chatAberto" class="janela-chat" ref="chatWindow">
        <div class="titulo-chat">
          <div class="titulo-box">
            <span class="fundo-logo-ia">
              <img
                v-for="imagem in imagens"
                :src="imagem.src"
                :key="imagem.key"
                :class="{
                  ativo: imagemAtual === imagem.key,
                  'robo-gif': !imagem.key.includes('Padrao'),
                }"
                class="logo-ia"
              />
            </span>

            <span
              class="titulo"
              @mousedown="startDrag"
              @mousemove="whileDragging"
              @mouseup="stopDrag"
              @mouseleave="stopDrag"
              ref="chatWindowDrag"
            >
              <span class="texto-nome"> Singú </span>
              <span class="texto-desc"> I.A. → Assistente Pessoal </span>
            </span>
          </div>
          <span class="icones-box">
            <img
              :src="iconeCarrossel"
              class="icone-ajuda"
              alt="icone ajuda assistente outbox"
              ref="iconeAjuda"
              @click="abreCarrossel = !abreCarrossel"
            />
            <span
              class="material-symbols-outlined icone-fechar"
              @click="chatAberto = false"
            >
              close
            </span>
          </span>
        </div>
        <div
          class="corpo-chat"
          ref="chatContainer"
          @scroll="monitoraScrollUsuario"
          @wheel="monitoraScrollUsuario"
        >
          <div
            v-for="(mensagem, indice) in historicoChatSuperbox"
            :key="indice"
            :style="{
              'justify-content':
                mensagem.enviado === true
                  ? 'flex-end'
                  : mensagem.enviado === false
                  ? 'flex-start'
                  : 'center',
            }"
            style="display: flex"
          >
            <div
              v-if="mensagem.divisor"
              style="display: flex; width: 100%; justify-content: center"
            >
              <div
                v-if="mensagem.divisor"
                v-html="mensagem.texto"
                class="mensagem-divisor"
              ></div>
            </div>
            <div
              v-else-if="
                (mensagem.tipo == 'texto' && mensagem.texto != '') ||
                (mensagem.tipo === 'imagem' && mensagem.imagem != '')
              "
              :class="{
                'mensagem-chat': true,
                enviado: mensagem.enviado,
                recebido: !mensagem.enviado,
              }"
            >
              <div
                v-if="mensagem.tipo == 'texto'"
                v-html="mensagem.texto"
                @click="handleClick($event)"
              ></div>
              <div v-if="mensagem.tipo === 'imagem' && mensagem.imagem != ''">
                <img
                  :src="'data:image/png;base64,' + mensagem.imagem"
                  alt="Imagem do chat"
                  class="chat-imagem"
                  @click="abrirPopup('data:image/png;base64,' + mensagem.imagem)"
                />
              </div>
              <div class="hora-msg">
                {{ mensagem.hora }}
              </div>
            </div>
          </div>
          <template v-if="!termosUsoAssistente">
            <div class="overlay">
              <div class="texto-termo">
                Usuário concorda com os
                <a :href="linkTermos" target="_blank">
                  <strong> Termos de Uso </strong>
                </a>
                do nosso serviço de chat, incluindo o uso de seus dados para melhorar a
                experiência de atendimento e a conformidade com nossas políticas de
                privacidade.
              </div>
              <div class="check-termo">
                <input
                  type="checkbox"
                  v-model="checkTermosUsoAssistente"
                  class="checkbox"
                />
                <span class="texto-check">Estou de acordo</span>
              </div>
              <div class="botoes-box">
                <button class="botao-termos declinar" @click="chatAberto = false">
                  Declinar
                </button>
                <button
                  class="botao-termos prosseguir"
                  :class="{
                    disabled: !checkTermosUsoAssistente,
                  }"
                  @click="prosseguirChat()"
                >
                  Prosseguir
                </button>
              </div>
            </div>
          </template>
        </div>

        <template>
          <div class="chat-container">
            <textarea
              v-model="entradaChat"
              placeholder="Mensagem..."
              @keydown="verificarEnvio"
              class="entrada-chat"
              maxlength="1000"
              @input="checkTransicao(entradaChat)"
            >
            </textarea>
            <div class="contador">{{ entradaChat.length }}/1000</div>
            <span
              v-if="!carregandoResposta && !carregandoRequisicao"
              class="material-symbols-outlined botao-enviar"
              @click="enviarChat()"
              :class="{
                disabled: entradaChat == '' || carregandoResposta || !termosUsoAssistente,
              }"
            >
              send
            </span>
            <span
              v-else-if="carregandoResposta && !carregandoRequisicao"
              class="material-symbols-outlined botao-enviar"
              @click="stopSimulacao()"
            >
              stop_circle
            </span>
            <md-progress-spinner
              v-if="carregandoRequisicao"
              :md-diameter="25"
              :md-stroke="2"
              md-mode="indeterminate"
              class="botao-enviar loading"
            >
            </md-progress-spinner>
            <div class="chat-aviso">
              Singú está sujeito a falhas. Sempre confira as informações.
            </div>
          </div>
        </template>
      </div>
    </transition>
    <div v-if="imagemPopup" class="popup-overlay" @click.self="fecharPopup">
      <div class="popup-content" ref="popupContent">
        <img
          :src="imagemPopup"
          alt="Imagem expandida"
          class="imagem-expandida"
          :style="{
            transform: `scale(${zoomLevel})`,
          }"
          ref="imagemExpandida"
          @wheel="handleZoomWheel"
        />
      </div>
      <div class="zoom-controls">
        <span class="material-symbols-outlined botoes-ajuste" @click="fecharPopup">
          close
        </span>
        <span
          class="material-symbols-outlined botoes-ajuste add"
          @click="zoomIn"
          :class="{ disabled: zoomLevel == 3 }"
        >
          zoom_in
        </span>
        <span
          class="material-symbols-outlined botoes-ajuste remove"
          @click="zoomOut"
          :class="{ disabled: zoomLevel == 1 }"
        >
          zoom_out
        </span>
        <span
          class="material-symbols-outlined botoes-ajuste restore"
          @click="restauraZoom"
        >
          crop_free
        </span>
      </div>
    </div>
    <div v-if="abreCarrossel" class="popup-overlay" @click.self="fechaCarrossel()">
      <div class="card-carrossel">
        <CarrosselSingu @fechaCarrossel="fechaCarrossel" />
      </div>
    </div>
    <div
      v-if="abreListaPerguntas"
      class="popup-overlay"
      @click.self="fechaListaPerguntas()"
    >
      <ListaPerguntasSugeridas
        :listaPerguntas="listaPerguntas"
        @enviaPerguntaSelecionada="enviaPerguntaSelecionada"
      />
    </div>
  </div>
</template>

<script>
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { chatOutboxFormatacoesStore } from "@/stores/chat-Outbox-formatacoes.js";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import CarrosselSingu from "@/components/AssistenteSingu/CarrosselSingu.vue";
import ListaPerguntasSugeridas from "@/components/AssistenteSingu/ListaPerguntasSugeridas.vue";

export default {
  name: "ChatOutbox",
  components: { CarrosselSingu, ListaPerguntasSugeridas },

  data() {
    return {
      dragging: false,
      posX: 0,
      posY: 0,
      stopDragFromDocument: null,

      logoIAPadrao: "/img/assistente-chat/Assistente-Outbox-padrao.png",
      logoIAPadraoBranco: "/img/assistente-chat/Assistente-Outbox-padrao-branco.png",

      fundoChat: "/img/assistente-chat/Assistente-Outbox-fundo-chat.png",

      imagens: [
        {
          key: "logoIAPadrao",
          src: "/img/assistente-chat/Assistente-Outbox-padrao-floating.gif",
          imagem: true,
        },
        {
          key: "logoRoboParaPonto",
          src: "/img/assistente-chat/Assistente-Outbox-RoboParaPontos.gif",
          imagem: true,
        },
        {
          key: "logoDigitando",
          src: "/img/assistente-chat/Assistente-Outbox-Pontos.gif",
          imagem: true,
        },
        {
          key: "logoPontoParaRobo",
          src: "/img/assistente-chat/Assistente-Outbox-PontoParaRobo.gif",
          imagem: true,
        },
      ],

      listaPerguntas: [
        "Traga o <strong>mapa</strong> do estado do Rio de Janeiro distribuindo os pontos no mapa com base nos registros. Os registros com <strong>score de fragilidade</strong> <strong>acima de 400</strong> terão cor azul e aqueles <strong>abaixo de 400</strong> terão cor vermelha limite a 2000 resultados coloque o ponto de <strong>localização do cliente</strong> como um pino verde no mapa.",
        "Faça um gráfico de distribuição de densidade de <strong>renda</strong> para a <strong>persona A03</strong> e indique nele com uma reta vermelha a <strong>renda do cliente</strong>.",
        "Crie um gráfico que mostra a distribuição de densidade do <strong>score de localidade</strong> para a cidade do <strong>Rio de Janeiro</strong> e compare com a cidade de <strong>São Paulo</strong> e adicione uma linha vermelha que representa o score de <strong>localidade do cliente</strong>.",
        "Mostre no mapa do brasil a<strong> média de score de fragilidade</strong> por estado use tonalidades de azul.",
        "Apresente em um gráfico que compara a <strong>média de score de inadimplência por persona</strong>, coloque a legenda das abscissas na vertical em ordem alfabética.",
        "Faça um gráfico que compara a <strong>média de renda</strong> para a persona <strong>I47</strong> com a persona <strong>I48, I49 e H42</strong> e indique no grafico o valor da média.",
        "Faça um gráfico de histograma da <strong>renda presumida do Brasil</strong> em faixa <strong>faixas de 5000</strong>, agrupando os valores acima de 50000 em uma faixa específica. Inclua uma marcação da renda presumida cliente na <strong>faixa em que o valor consultado cair</strong>,ajuste o eixo x do gráfico para 45 graus.",
      ],

      imagemAtual: "logoIAPadrao",
      timerId: null,

      dataFormatada: "",
      horaFormatada: "",

      carregandoResposta: false,
      carregandoRequisicao: false,
      contextoAtual: {
        codigo_agrupador: "",
        rota: "/inicio",
        nome: "",
        descricao: "",
      },

      linkTermos: "/docs/Termos de serviço - Assistente Outbox.pdf",

      textoCompletoSimulado: "",
      idIntervaloSimulacao: 0,
      caminhoImagemTemporario: "",

      checkTermosUsoAssistente: false,

      listaAvisosInvalido: [
        "Desculpe, não consegui entender sua pergunta. Poderia reformulá-la?",
        "Ops, não consegui captar o que você quis dizer. Poderia explicar de outra forma?",
        "Parece que não entendi sua pergunta. Você poderia reformular, por favor?",
        "Hmm, não consegui processar sua pergunta corretamente. Tente me dizer de outra maneira.",
        "Desculpe, não entendi o que você quis dizer. Poderia ser um pouco mais claro?",
        "Não consegui compreender sua pergunta. Você poderia ser mais específico?",
        "Acho que não captei sua questão. Você pode tentar novamente, por favor?",
        "Poxa, parece que não entendi. Você pode reescrever sua pergunta?",
        "Minhas desculpas, mas não consegui entender sua pergunta. Você poderia tentar de novo?",
        "Uh oh, acho que me confundi. Pode reformular sua pergunta?",
        "Não entendi bem isso. Você se importa de explicar de outra forma?",
      ],

      listaAvisosContexto: [
        "Oops... Ainda estou aprendendo! Hoje só posso te responder se você estiver em alguma das páginas de consultas Superbox.",
        "Ops, ainda estou em treinamento! Por enquanto, só consigo responder perguntas nas páginas de consultas Superbox.",
        "Desculpe, ainda estou aprendendo. Neste momento, só posso ajudar nas páginas de consultas Superbox.",
        "Parece que estou um pouco limitado agora. Só posso responder dentro das páginas de consultas Superbox.",
        "Estou em fase de aprendizado e, por ora, só posso te ajudar nas páginas de consultas Superbox.",
        "Por enquanto, estou equipado para responder apenas nas páginas de consultas Superbox.",
        "Ainda estou em fase de treinamento. Me encontre nas páginas de consultas Superbox para te ajudar!",
        "Estou aprendendo mais a cada dia! No momento, só respondo nas páginas de consultas Superbox.",
        "Oops, minhas habilidades estão em desenvolvimento. Por enquanto, só posso responder em consultas Superbox.",
        "Ainda estou crescendo em conhecimento. Visite as páginas de consultas Superbox para obter respostas!",
        "Minha programação atual me permite responder apenas nas páginas de consultas Superbox. Nos vemos lá!",
      ],

      listaAvisosInicio: [
        "Bem-vindo, meu nome é Singú, sou seu Assistente Outbox, como posso ajudar?",
        "Olá! Bem-vindo ao Assistente Outbox. Como posso te ajudar hoje?",
        "Bem-vindo ao Assistente Outbox! Eu sou Singú e estou aqui para ajudar. O que você gostaria de saber?",
        "Seja bem-vindo ao nosso chat, sou Singú, seu Assistente Outbox. Como posso ser útil?",
        "Olá! Como posso ajudar você hoje?",
        "Oi! Posso ajudar em algo? Estou aqui para isso!",
        "Bem-vindo ao Assistente Outbox! Tem alguma dúvida que posso esclarecer?",
        "Olá, que bom ver você aqui no Assistente Outbox! Como posso ajudar?",
        "Seja bem-vindo ao Assistente Outbox. O que você precisa hoje?",
        "Olá, sou o Singú, seu Assistente Outbox, como posso te ajudar hoje?",
        "Olá! Singú pronto para te ajudar. Qual é a sua pergunta?",
      ],

      rolagemAutomaticaHabilitada: true,

      imagemPopup: null,
      zoomLevel: 1,
      currentOffsetX: 0,
      currentOffsetY: 0,
      maxZoom: 3,
      minZoom: 1,
      zoomStep: 0.2,

      iconeCarrossel: "/img/assistente-chat/icone_carrossel_chat.svg",
      iconeListaPerguntas: "/img/assistente-chat/icone-lista-perguntas-chat.svg",
    };
  },

  watch: {
    $route: function (to, from) {
      if (this.chatAberto) {
        this.chatAberto = false;
      }
    },

    abrirChat() {
      if (this.abrirChat) {
        this.alternarChat(false);
        this.abrirChat = false;
      }
    },
  },

  methods: {
    handleClick(event) {
      const target = event.target;
      if (target.classList.contains("linkDinamico")) {
        const tipoLink = target.dataset.tipo;
        this.liberarLink(tipoLink);
      }
    },

    liberarLink(link) {
      this.abreListaPerguntas = false;
      this.abreCarrossel = false;

      if (link == "[link_carrossel]") {
        this.abreCarrossel = true;
      }

      if (link == "[link_perguntas]") {
        this.abreListaPerguntas = true;
      }
    },

    liberarListaPerguntas() {
      this.abreListaPerguntas = true;
    },

    fechaCarrossel() {
      this.abreCarrossel = false;
    },
    fechaListaPerguntas() {
      this.abreListaPerguntas = false;
    },

    abrirPopup(src) {
      this.imagemPopup = src;
    },

    fecharPopup() {
      this.imagemPopup = null;
      this.zoomLevel = 1;
      this.currentOffsetX = 0;
      this.currentOffsetY = 0;
    },

    handleZoomWheel(event) {
      if (event.ctrlKey) {
        event.preventDefault();
        if (event.deltaY > 0) {
          this.zoomOut();
        } else {
          this.zoomIn();
        }
      }
    },

    zoomIn() {
      if (this.zoomLevel <= this.maxZoom) {
        this.zoomLevel = Math.min(this.zoomLevel + this.zoomStep, this.maxZoom);
        this.adjustImagePosition();
      }
    },
    zoomOut() {
      if (this.zoomLevel >= this.minZoom) {
        this.zoomLevel = Math.max(this.zoomLevel - this.zoomStep, this.minZoom);
        this.adjustImagePosition();
      }
    },

    adjustImagePosition() {
      const img = this.$refs.imagemExpandida;
      const container = this.$refs.popupContent;

      if (this.zoomLevel != 1) {
        if (img.offsetWidth * this.zoomLevel > 855) {
          img.style.transformOrigin = "top left";
          img.style.right = "100px";
        } else {
          img.style.transformOrigin = "top center";
          img.style.right = "0px";
        }
      } else {
        img.style.transformOrigin = "top center";
        img.style.right = "0px";
        this.currentOffsetY = 0;
        this.currentOffsetX = 0;
      }
    },

    enviaPerguntaSelecionada(pergunta) {
      this.alternarChat(false);
      this.abreListaPerguntas = false;
      this.entradaChat = "";
      this.entradaChat = pergunta;
      this.checkTransicao(this.entradaChat);
    },

    restauraZoom() {
      this.zoomLevel = 1;
      this.currentOffsetY = 0;
      this.currentOffsetX = 0;

      const img = this.$refs.imagemExpandida;
      img.style.transformOrigin = "top center";
      img.style.right = "0px";
    },

    updateImagePosition() {
      const img = this.$refs.imagemExpandida;
      img.style.transform = `scale(${this.zoomLevel}) translate(${this.translateX}px, ${this.translateY}px)`;
    },

    checkTransicao(texto) {
      clearTimeout(this.timerId);

      if (texto.length > 0 && this.imagemAtual !== "logoDigitando") {
        this.imagemAtual = "logoRoboParaPonto";
        this.timerId = setTimeout(() => {
          this.imagemAtual = "logoDigitando";
        }, 1000);
      } else if (texto.length === 0 && this.imagemAtual !== "logoIAPadrao") {
        this.imagemAtual = "logoPontoParaRobo";
        this.timerId = setTimeout(() => {
          this.imagemAtual = "logoIAPadrao";
        }, 1000);
      }
    },

    async alternarChat(estadoAtual) {
      if (!estadoAtual) {
        this.abrirJanelaChat();
        await this.varificaPreferenciaTermos();
        this.atualizarContexto(this.contexto);
      } else {
        this.fecharJanelaChat();
      }

      if (this.chatAberto && this.termosUsoAssistente) {
        this.verificarContexto();
      }
    },

    async prosseguirChat() {
      const nomePrefTermosDeUso = "PortalOutbox_TermosDeUsoAssistente";
      await this.substituiPreferencia(nomePrefTermosDeUso, this.checkTermosUsoAssistente);
      this.termosUsoAssistente = this.checkTermosUsoAssistente;

      this.verificarContexto();
    },

    verificarContexto() {
      const ultimoContexto =
        this.historicoChatSuperbox.length > 0
          ? this.historicoChatSuperbox[this.historicoChatSuperbox.length - 1].contexto
          : null;

      if (this.historicoChatSuperbox.length > 0 && ultimoContexto) {
        const rotaMudou = ultimoContexto.rota !== this.contextoAtual.rota;
        const codigoMudou =
          ultimoContexto.codigo_agrupador !== this.contextoAtual.codigo_agrupador &&
          this.contextoAtual.codigo_agrupador !== "";

        if (rotaMudou && (codigoMudou || this.contextoAtual.codigo_agrupador === "")) {
          this.adicionarDivisorDeContexto();
        }
      }

      if (this.historicoChatSuperbox.length === 0) {
        this.adicionarMensagemBoasVindas();
      }

      this.$nextTick(() => {
        this.scrollParaBaixo();
      });
    },

    async adicionarMensagemBoasVindas() {
      this.carregandoResposta = true;

      this.atualizaHora();

      const mensagemBoasVindas = this.getFraseAleatoriaInicio();
      await this.simularDigitacao(mensagemBoasVindas);
    },

    getFraseAleatoriaInicio() {
      const indiceAleatorio = Math.floor(Math.random() * this.listaAvisosInicio.length);
      return this.listaAvisosInicio[indiceAleatorio];
    },

    verificarEnvio(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        this.enviarChat();
      }
    },

    atualizaHora() {
      const agora = new Date();
      this.dataFormatada = "";
      this.dataFormatada = agora.toLocaleDateString("pt-BR");
      this.horaFormatada = "";
      this.horaFormatada = agora.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    async enviarChat() {
      this.rolagemAutomaticaHabilitada = true;

      if (
        !this.carregandoResposta &&
        !this.carregandoRequisicao &&
        this.entradaChat != "" &&
        this.termosUsoAssistente
      ) {
        // this.stopSimulacao();
        this.carregandoResposta = true;
        const pergunta = this.entradaChat.replace(/\n/g, "<br>");
        this.atualizaHora();

        this.historicoChatSuperbox.push({
          data: this.dataFormatada,
          hora: this.horaFormatada,
          texto: await this.processarMarkdown(pergunta),
          enviado: true,
          contexto: this.contexto,
          tipo: "texto",
        });
        this.entradaChat = "";

        this.$nextTick(() => {
          this.scrollParaBaixo();
        });

        await this.obterResposta(pergunta);
      }
    },

    adicionarDivisorDeContexto() {
      let contextoTexto = this.contextoAtual.descricao;
      if (this.contextoAtual.codigo_agrupador) {
        contextoTexto +=
          " <br/> Código agrupador: " + this.contextoAtual.codigo_agrupador;
      }

      this.historicoChatSuperbox.push({
        texto: contextoTexto,
        divisor: true,
        contexto: this.contextoAtual,
      });
    },

    atualizarContexto(novoContexto) {
      this.contextoAtual = novoContexto;
    },

    async obterResposta(pergunta) {
      if (this.contexto.codigo_agrupador && this.contexto.codigo_agrupador != "") {
        const letras = pergunta.match(/[a-zA-Z]/g);
        if (letras && letras.length >= 2) {
          this.carregandoRequisicao = true;

          await this.requisicaoChatSuperbox(
            this.contexto.codigo_agrupador,
            pergunta,
            this.thread_id
          );
          this.carregandoRequisicao = false;

          let respostaMarkdown = "";

          if (this.resultadoRequisiçãoSuperbox.resposta) {
            respostaMarkdown = this.resultadoRequisiçãoSuperbox.resposta;
          } else if (typeof this.resultadoRequisiçãoSuperbox == "string") {
            respostaMarkdown = this.resultadoRequisiçãoSuperbox;
          } else {
            respostaMarkdown = "Assistente indisponível.";
          }

          if (this.resultadoRequisiçãoSuperbox.thread_id) {
            this.thread_id = this.resultadoRequisiçãoSuperbox.thread_id;
          }

          if (this.resultadoRequisiçãoSuperbox.imagem) {
            this.caminhoImagemTemporario = this.resultadoRequisiçãoSuperbox.imagem;
          }

          const respostaProcessada = this.processandoTexto(respostaMarkdown);
          await this.simularDigitacao(respostaProcessada);

          if (this.caminhoImagemTemporario != "") {
            this.criaMensagemImage();
          }

          this.$nextTick(() => this.scrollParaBaixo());
        }
      } else {
        const textoPermissao = this.getFraseAleatoriaContexto();
        await this.simularDigitacao(textoPermissao);
        this.$nextTick(() => this.scrollParaBaixo());
      }
    },

    async criaMensagemImage() {
      this.atualizaHora();

      if (this.caminhoImagemTemporario != null && this.caminhoImagemTemporario !== "") {
        this.historicoChatSuperbox.push({
          data: this.dataFormatada,
          hora: this.horaFormatada,
          texto: "",
          enviado: false,
          contexto: this.contexto,
          tipo: "imagem",
          imagem: this.caminhoImagemTemporario,
        });
      }
      this.checkTransicao("");

      this.$nextTick(() => this.scrollParaBaixo());
      this.caminhoImagemTemporario = "";
    },

    getFraseAleatoriaInvalido() {
      const indiceAleatorio = Math.floor(Math.random() * this.listaAvisosInvalido.length);
      return this.listaAvisosInvalido[indiceAleatorio];
    },

    getFraseAleatoriaContexto() {
      const indiceAleatorio = Math.floor(Math.random() * this.listaAvisosContexto.length);
      return this.listaAvisosContexto[indiceAleatorio];
    },

    processandoTexto(texto) {
      if (texto) {
        texto = this.limpezaTagsIncompletas(texto);
        texto = this.processarMarkdown(texto);
        texto = this.formataTextoCodigo(texto);
        texto = this.formataTextoTabela(texto);
        texto = this.formataTextoLink(texto);
        texto = this.formatarQuebrasDeLinha(texto);
      }

      return texto;
    },

    async simularDigitacao(texto) {
      let i = 0;
      const velocidade = 20;
      this.textoCompletoSimulado = texto;
      this.checkTransicao(texto);

      this.historicoChatSuperbox.push({
        data: this.dataFormatada,
        hora: this.horaFormatada,
        texto: "",
        enviado: false,
        contexto: this.contexto,
        simulação: true,
        tipo: "texto",
      });

      return new Promise((resolve) => {
        this.idIntervaloSimulacao = setInterval(() => {
          if (i < texto.length) {
            let textoParcial = texto.substring(0, i + 1);

            if (texto[i] === "<") {
              const closeTagIndex = texto.indexOf(">", i);
              if (closeTagIndex !== -1) {
                textoParcial = texto.substring(0, closeTagIndex + 1);
                i = closeTagIndex;
              }
            }

            this.atualizarChat(textoParcial, true);
            i++;
          } else {
            clearInterval(this.idIntervaloSimulacao);
            this.idIntervaloSimulacao = null;
            resolve();

            this.checkTransicao("");
            this.$nextTick(() => this.scrollParaBaixo());
            this.carregandoResposta = false;
          }
        }, velocidade);
      });
    },

    stopSimulacao() {
      if (this.idIntervaloSimulacao) {
        clearInterval(this.idIntervaloSimulacao);
        this.idIntervaloSimulacao = null;

        const ultimoTexto = this.historicoChatSuperbox.pop();
        const jaExiste = this.historicoChatSuperbox.some(
          (item) => item.texto === this.textoCompletoSimulado
        );

        if (!jaExiste) {
          this.historicoChatSuperbox.push({
            ...ultimoTexto,
            texto: this.textoCompletoSimulado,
            simulação: false,
            tipo: "texto",
          });
        }

        this.$nextTick(() => this.scrollParaBaixo());
        this.carregandoResposta = false;
      }
      this.criaMensagemImage();
      this.checkTransicao("");
    },

    atualizarChat(texto, isSimulating = false) {
      if (isSimulating) {
        this.historicoChatSuperbox.pop();
      }

      this.historicoChatSuperbox.push({
        data: this.dataFormatada,
        hora: this.horaFormatada,
        texto: texto,
        enviado: false,
        contexto: this.contexto,
        tipo: "texto",
      });

      this.$nextTick(() => this.scrollParaBaixo());
    },

    aplicarFormato(texto, tipo) {
      if (tipo === "negrito") {
        return `<strong>${texto}</strong>`;
      } else if (tipo === "italico") {
        return `<em>${texto}</em>`;
      }
      return texto;
    },

    scrollParaBaixo() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer && this.rolagemAutomaticaHabilitada) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    },

    monitoraScrollUsuario(event) {
      let chatContainer = this.$refs.chatContainer;
      const estaNoFundo =
        chatContainer.scrollHeight - chatContainer.scrollTop - 20 <
        chatContainer.clientHeight;

      this.rolagemAutomaticaHabilitada = estaNoFundo;
    },

    startDrag(event) {
      this.dragging = true;
      this.posX = event.clientX;
      this.posY = event.clientY;

      document.addEventListener("mousemove", this.whileDragging);
      document.addEventListener("mouseup", this.stopDrag);
      document.addEventListener("mouseleave", this.stopDragFromDocument);
    },

    whileDragging(event) {
      if (this.dragging) {
        let deltaX = event.clientX - this.posX;
        let deltaY = event.clientY - this.posY;

        let chatWindow = this.$refs.chatWindow;
        let rect = chatWindow.getBoundingClientRect();

        let newRight = parseInt(window.getComputedStyle(chatWindow).right, 10) - deltaX;
        let newBottom = parseInt(window.getComputedStyle(chatWindow).bottom, 10) - deltaY;

        newRight = Math.min(Math.max(newRight, 0), window.innerWidth - rect.width - 250);

        newBottom = Math.min(
          Math.max(newBottom, -55),
          window.innerHeight - rect.height - 50
        );

        chatWindow.style.right = `${newRight}px`;
        chatWindow.style.bottom = `${newBottom}px`;

        this.posX = event.clientX;
        this.posY = event.clientY;
      }
    },

    stopDrag() {
      this.dragging = false;

      document.removeEventListener("mousemove", this.whileDragging);
      document.removeEventListener("mouseup", this.stopDrag);
    },

    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translate(100%, 50%) scale(0)";
    },
    enter(el, done) {
      el.offsetHeight;
      el.style.transition = "opacity 0.4s ease-out, transform 0.4s ease-out";
      el.style.opacity = 1;
      el.style.transform = "translate(0%, -10%) scale(1)";
      done();
    },
    leave(el, done) {
      el.style.transition = "opacity 0.4s ease-in, transform 0.4s ease-in";
      el.style.opacity = 0;
      el.style.transform = "translate(150%, 70%) scale(0)";
      setTimeout(done, 500);
    },

    iniciarPulsacao() {
      const iconeRobo = this.$refs.iconeRobo;

      if (iconeRobo) {
        const reiniciarAnimacao = () => {
          iconeRobo.style.animation = "none";
          setTimeout(() => {
            iconeRobo.style.animation = "";
          }, 10);
        };

        setInterval(reiniciarAnimacao, 30000);
      }
    },

    preloadImages(imageSources) {
      imageSources.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    },

    async varificaPreferenciaTermos() {
      const nomePrefTermosDeUso = "PortalOutbox_TermosDeUsoAssistente";
      const preferenciasFavoritos = await this.syncPreferenciaGeral(nomePrefTermosDeUso);
      if (preferenciasFavoritos === undefined) {
        await this.createPreferencia(nomePrefTermosDeUso);
        await this.substituiPreferencia(nomePrefTermosDeUso, false);
      } else {
        if (preferenciasFavoritos.Valor && preferenciasFavoritos.Valor != "") {
          this.termosUsoAssistente = preferenciasFavoritos.Valor == "true";
        }
      }
    },

    atualizaPreferencia() {},

    ...mapActions(chatOutboxStore, [
      "resetStoreChat",
      "requisicaoChatSuperbox",
      "abrirJanelaChat",
      "fecharJanelaChat",
      "recebeResultadoSuperbox",
    ]),

    ...mapActions(chatOutboxFormatacoesStore, [
      "processarMarkdown",
      "formatarQuebrasDeLinha",
      "formataTextoCodigo",
      "formataTextoTabela",
      "formataTextoLink",
      "limpezaTagsIncompletas",
    ]),

    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),
  },

  computed: {
    digitando() {
      if (this.entradaChat.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapWritableState(chatOutboxStore, {
      liberaIconeChat: "liberaIconeChat",
      chatAberto: "chatAberto",
      abrirChat: "abrirChat",
      entradaChat: "entradaChat",
      historicoChatSuperbox: "historicoChatSuperbox",
      contexto: "contexto",
      resultadoRequisiçãoSuperbox: "resultadoRequisiçãoSuperbox",
      thread_id: "thread_id",
      resumoResultadoSuperbox: "resumoResultadoSuperbox",
      abreListaPerguntas: "abreListaPerguntas",
      abreCarrossel: "abreCarrossel",
    }),

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      termosUsoAssistente: "termosUsoAssistente",
    }),
  },

  async mounted() {
    this.resetStoreChat();
    this.preloadImages(this.imagens.map((imagem) => imagem.src));

    setTimeout(() => {
      this.iniciarPulsacao();
    }, 10000);
  },
};
</script>

<style lang="scss" scoped>
.icone-chat {
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: #0389dd;
  // background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  color: white;
}

.icone-robo {
  width: 30px;
  height: 30px;
  font-weight: 300;
  font-size: 26px;
  display: inline-block;
  animation: pulsar 3s ease-in-out infinite;
  animation-delay: 27s;
  transition: 0.5s;
  transform: scale(1.1);

  &:hover {
    transform: scale(1.2);
  }
}

@keyframes pulsar {
  0%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.1);
  }
}

.janela-chat {
  position: fixed;
  bottom: 0px;
  right: 10%;
  width: 350px;
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  z-index: 999;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.65);
  background-image: url(/img/assistente-chat/Assistente-Outbox-fundo-chat.png);
  background-repeat: repeat;
  background-size: cover;
}

.titulo-chat {
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding: 0 10px;
  background-color: white;
  border-radius: 10px;
  height: 70px;
  user-select: none;
}

.titulo-box {
  display: flex;
  width: 80%;
  height: 52px;
}

.titulo {
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 21px;
  margin: 0 0 0 10px;

  &:active {
    cursor: grabbing;
  }
}

.texto-nome {
  color: #2b3769;
  font-size: 18px;
  font-weight: 500;
}

.texto-desc {
  font-size: 14px;
  font-weight: 300;
  color: #a1a1a1;
}

.fundo-logo-ia {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-ia {
  width: 37px;
  height: 37px;
}

.fundo-logo-ia img {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  position: absolute;
}

.fundo-logo-ia img.ativo {
  opacity: 1;
  position: absolute;
}

.robo-gif {
  width: 37px;
  height: 37px;
}

.icones-box {
  display: flex;
  gap: 10px;
  cursor: default;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.icone-info {
  cursor: default;
  color: #007bff;

  opacity: 0.5;

  &:hover {
    opacity: 0.7;
  }
}

.icone-fechar {
  cursor: pointer;
  font-size: 28px;
  opacity: 0.3;

  &:hover {
    opacity: 0.5;
  }
}

.corpo-chat {
  height: 450px;
  max-height: 450px;
  overflow-y: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.mensagem-divisor {
  display: flex;
  align-items: center;
  text-align: center;
  font-style: italic;
  color: #888;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  width: 100%;
  background-color: rgb(252, 252, 252);
  border-radius: 15px;
  padding: 5px;
  margin: 10px 5px;
}

.mensagem-divisor:before,
.mensagem-divisor:after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.mensagem-divisor:before {
  margin-right: 10px;
}

.mensagem-divisor:after {
  margin-left: 10px;
}

.mensagem-chat {
  position: relative;
  margin: 10px;
  background-color: #6d6d6d;
  border-radius: 10px;
  max-width: 85%;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  cursor: default;
  word-break: break-word;
  width: fit-content;
}

.chat-imagem {
  transition: transform 0.5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }
}

.enviado {
  background-color: #703eff;
  color: white;
  text-align: right;
  align-self: flex-end;
}

.recebido {
  background-color: rgb(251, 251, 251);
  color: black;
  text-align: left;
  align-self: flex-start;
}

.enviado::after {
  content: "";
  position: absolute;
  bottom: 4px;
  right: -5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #703eff;
  transform: rotate(-90deg) scale(1.4);
  border-radius: 20%;
}

.recebido::after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: -5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: rgb(251, 251, 251);
  transform: rotate(90deg) scale(1.4);
  border-radius: 20%;
}

.hora-msg {
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  text-align: right;
}

.chat-container {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.entrada-chat {
  width: 100%;
  border: none;
  background-color: #e9e9e9;
  color: black;
  height: 50px;
  border-radius: 30px;
  max-height: 80px;
  min-height: 50px;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  top: 5px;
  padding: 10px 77px 10px 20px;
  line-height: 16px;
}

.contador {
  position: absolute;
  right: 45px;
  bottom: 43px;
  background-color: transparent;
  color: #666;
  font-size: 12px;
  line-height: 16px;
}

.botao-enviar {
  position: absolute;
  right: 15px;
  bottom: 37px;
  background-color: transparent;
  color: #0389dd;
  font-size: 26px;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.5;
  line-height: 26px;

  &.loading {
    bottom: 40px;
    cursor: default;
  }

  &:hover {
    opacity: 0.7;
  }
}

.chat-aviso {
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  text-align: center;
  color: #808080;
  position: relative;
  top: 4px;
}

.disabled {
  pointer-events: none;
  color: #a1a1a1;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 450px;
  background-color: #45454587;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 15px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  padding: 0 10px;
}

.check-termo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .checkbox {
    margin: 5px 5px 0 0;
  }

  .texto-check {
    font-weight: 400;
  }
}

.botoes-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;

  .botao-termos {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    height: 35px;
    width: 120px;

    &.prosseguir {
      background-color: #007bff;
      color: white;
    }

    &.declinar {
      background-color: white;
      color: black;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background: white;
  padding: 10px 15px 10px 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.2s ease-in-out;
  width: 60%;
  height: 90%;
  overflow: auto;
  transform: translateX(10%);

  &.carrossel {
    background: transparent;
    width: 50%;
    overflow: hidden;
  }
}

.imagem-expandida {
  max-width: 95%;
  max-height: 95%;
  transition: transform 0.2s ease-in-out;
  transform-origin: top center;
  position: relative;
}

.zoom-controls {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  height: 86%;
  width: 50px;
  position: relative;
  left: 2%;
}

.botoes-ajuste {
  color: #585858;
  cursor: pointer;
  font-size: 26px;
  transition: 0.3s;
  z-index: 10;

  &:hover {
    transform: scale(1.05);
  }
}

.icone-ajuda {
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  &:active {
    opacity: 1;
    transform: scale(0.95);
  }
}

.card-carrossel {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 30px 30px 30px rgba(23, 55, 127, 0.3);
  display: flex;
  flex-direction: column;
  padding: 20px;
  place-items: flex-start;
  transition: 0.5s;
  height: 555px;
  width: 460px;
  margin: 20px auto;
  border: none;

  &:hover {
    transform: scale(1.05);
  }
}

.entrada-chat::placeholder {
  color: #303030;
  font-size: 16px;
  opacity: 1;
  line-height: 17px;
}

.corpo-chat::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.corpo-chat::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 10px;
}

.corpo-chat::-webkit-scrollbar-track {
  background: #dadada;
}

.entrada-chat::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.entrada-chat::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 10px;
}

.entrada-chat::-webkit-scrollbar-track {
  background: white;
}
</style>
